import React from "react";
import SEO from "components/seo";
import Bio from "components/ourPeople/bio";

const ourPeopleData = require("data/our-people.json");
const { people } = ourPeopleData;
const ourPeopleCollection = people.map(p => (
  <Bio
    key={p.name}
    id={p.id}
    name={p.name}
    data_target={p.data_target}
    img={p.img}
    title={p.title}
    division={p.division}
    tenure={p.tenure}
    describe_job={p.describe_job}
    describe_work={p.describe_work}
    found_thomas={p.found_thomas}
    past_time={p.past_time}
    great_place_to_work={p.great_place}
  />
));

const OurPeople = () => {
  return (
    <main style={{ backgroundColor: "white" }}>
      <SEO title="Our People | Thomas Careers" />
      <section className="page-utility">
        <div className="container">
          <nav aria-label="breadcrumb" style={{ backgroundColor: "white" }}>
            <ol
              className="breadcrumb"
              style={{ marginBlockStart: "0", marginBlockEnd: "0" }}
            >
              <li className="breadcrumb-item">
                <a href="https://business.thomasnet.com/about/">About</a>
              </li>
              <li className="breadcrumb-item">
                <span xmlns="http://rdf.data-vocabulary.org/#" />
                <span className="breadcrumb_last">Our People</span>
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <header className="page-header" style={{ backgroundColor: "white" }}>
        <div className="container" style={{ backgroundColor: "white" }}>
          <h1 className="page-header__title font-weight-normal" style={{fontSize:"50px"}}>Our People</h1>
        </div>
      </header>
      <div className="container" style={{ backgroundColor: "white" }}>
        <p className="lead">
          Our customers sing the praises of not only our services, but they
          continuously praise our people as well – and, for good reason. Thomas
          continues to attract the best and the brightest to our various Thomas
          Publishing divisions. From stellar sales people to digital marketing
          gurus, below are just a few of the many talented people that work
          here.
        </p>
        <section className="our-people-section">
          <div className="cols-3">{ourPeopleCollection}</div>
        </section>
      </div>
    </main>
  );
};

export default OurPeople;
