import React, { useState } from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement("#___gatsby");

const Bio = ({
  id,
  name,
  data_target,
  img,
  title,
  division,
  tenure,
  describe_job,
  describe_work,
  found_thomas,
  past_time,
  great_place_to_work
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="ops-item-wrap">
      <a
        href="#"
        className="ops-item"
        data-toggle="modal"
        data-target={data_target}
      >
        <img
          src={img}
          alt={name}
          className="img-fluid"
          onClick={() => setIsModalOpen(!isModalOpen)}
        />
      </a>
        <ReactModal
          className="Modal__Bootstrap modal-dialog modal-lg modal-dialog-centered"
          closeTimeoutMS={300}
          isOpen={isModalOpen}
          contentLabel="Personalize"
          onRequestClose={() => setIsModalOpen(false)}
          overlayClassName="ReactModal__Overlay"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsModalOpen(false)}
              >
                <span aria-hidden="true">&times;</span>
                <span className="sr-only">Close</span>
              </button>
              <h2>
                <span>{name}</span>
              </h2>
              <p>
                <strong>Title:</strong> {title}
                <br />
                <strong>Division:</strong> {division} <br />
                <strong>At Thomas:</strong> {tenure}
              </p>
              <p>
                <strong>
                  What one word best describes your job at Thomas?
                </strong>
                <br />
                {describe_job}
              </p>
              <p>
                <strong>
                  For you, what makes Thomas a great place to work?
                </strong>
                <br />
                {great_place_to_work}
              </p>
              <p>
                <strong>
                  Describe, in a few sentences, something exciting you recently
                  worked on.
                </strong>
                <br />
                {describe_work}
              </p>
              <p>
                <strong>How did you find your job at Thomas? </strong>
                <br />
                {found_thomas}
              </p>
              <p>
                <strong>Name a favorite past time outside of work.</strong>
                <br />
                {past_time}
              </p>
            </div>
          </div>
          </ReactModal>
      </div>
  );
};

export default Bio;
